<template>
  <div v-if="data.length > 0" class="ss-container">
    <div
      v-for="(item, index) in data"
      :key="index + 1"
      class="d-flex flex-column-reverse flex-md-row justify-content-center justify-content-md-between"
    >
      <div class="item-image-wrapper">
        <div
          v-for="(img, index) in item.orig_img"
          :key="index + 1"
          class="item-image"
          :class="item.orig_img.length > 1 && index > 0 ? 'mt-20' : ''"
        >
          <img :src="img" :alt="item.title" />
        </div>
      </div>
      <div
        class="item-content"
        :class="category === 'commercial-illustration' ? 'comm' : ''"
      >
        <h4>{{ item.title }}</h4>
        <h4
          v-if="category === 'commercial-illustration' && item.titleEN !== ''"
        >
          {{ item.titleEN }}
        </h4>
        <p v-if="item.description">
          {{ item.description }}
        </p>

        <div
          v-if="
            category === 'commercial-illustration' ||
              category === 'book-cover-design'
          "
          class="intro"
        >
          <p>
            類別
            <span v-html="item.category"> </span>
          </p>
          <p>
            <span v-if="category === 'commercial-illustration'">客戶</span>
            <span v-if="category === 'book-cover-design'">出版</span>
            <span v-html="item.client"> </span>
          </p>

          <a v-if="item.link" :href="item.link" target="_blank">
            完整專案介紹
            <span>
              <img :src="require('../assets/icon_more_bk.svg')" alt="箭頭" />
            </span>
          </a>
        </div>
      </div>
    </div>

    <router-link
      v-if="this.data[0].id > 1"
      :to="prevProjectLink"
      class="control prev"
    >
      <img :src="require('../assets/icon_prev.svg')" alt="前一項" />
    </router-link>
    <router-link
      v-if="this.data[0].id < maxItemID"
      :to="nextProjectLink"
      class="control next"
    >
      <img :src="require('../assets/icon_next.svg')" alt="後一項" />
    </router-link>
  </div>
</template>

<script>
export default {
  name: "ItemTemplate",
  props: {
    data: Array,
    category: String,
    maxItemID: Number
  },
  data() {
    return {};
  },
  computed: {
    nextProjectLink() {
      return "/" + this.category + "/" + (this.data[0].id + 1);
    },
    prevProjectLink() {
      return "/" + this.category + "/" + (this.data[0].id - 1);
    }
  }
};
</script>

<style scoped lang="sass">
.mt-20
  margin-top: 20px
.ss-container
  position: relative
.item-image-wrapper
  width: 100%
  +deviceWidth()
    padding-bottom: 60px
  +deviceWidth(1200,0)
    margin-left: 110px
  .item-image
    width: 100%
    overflow: hidden
    border-radius: 18px
    img
      max-width: 100%
      height: auto
.item-content
  width: 100%
  +deviceWidth(768,0)
    max-width: 245px
  +deviceWidth(768,1199)
    margin-left: 55px
  +deviceWidth(1200,0)
    margin-left: 225px
    margin-right: 100px
    padding-top: 203px
  &.comm
    +deviceWidth(1200,0)
      padding-top: 88px
    h4
      letter-spacing: 0.35px
    p
      opacity: 0.9
      font-family: 'PingFang TC', 'Microsoft JhengHei', sans-serif
      font-size: 12px
      line-height: 2
      letter-spacing: 1px
      color: #111111
  h4
    font-size: 14px
    letter-spacing: .88px
    font-family: 'PingFang TC', 'Microsoft JhengHei', sans-serif
    font-weight: 500
    margin-bottom: 8px
    opacity: .95
    line-height: 1.7
  p
    font-size: 14px
    letter-spacing: 1.17px
    font-family: 'PingFang TC', 'Microsoft JhengHei', sans-serif
    line-height: 1.7
    opacity: .5
    margin-bottom: 0
    +deviceWidth()
      margin-bottom: 16px
  .intro
    margin-top: 28px
    +deviceWidth()
      margin-bottom: 16px
    p
      opacity: 0.9
      font-family: 'PingFang TC', 'Microsoft JhengHei', sans-serif
      font-size: 12px
      line-height: 2
      letter-spacing: 1px
      color: #111111
      display: flex
      +deviceWidth()
        margin-bottom: 0
      span
        &:last-child
          display: inline-block
          opacity: 1
          font-weight: 500
          margin-left: 12px
          max-width: 200px
    a
      display: inline-block
      margin-top: 48px
      opacity: 0.9
      font-family: 'PingFang TC', 'Microsoft JhengHei', sans-serif
      font-size: 12px
      letter-spacing: 1px
      color: #111111
      +deviceWidth()
        margin-top: 24px
      &:hover
        text-decoration: none
      &:focus
        outline: none
      span
        display: inline-block
        width: 15px
        img
          max-width: 100%
          height: auto
.control
  display: inline-block
  position: absolute
  top: 100%
  background: none
  border: none
  +deviceWidth(768,1199)
    top: 105%
  +deviceWidth(1200,0)
    top: 208px
  &:focus
    outline: none
  &.next
    right: 20px
    +deviceWidth(1200,0)
      right: 60px
  &.prev
    left: 20px
    +deviceWidth(1200,0)
      left: 60px
  img
    max-width: 100%
    height: auto
</style>
